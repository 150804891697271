import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Inv No.', 'invoiceNo', true, 'desc', 'left'),
  new TableColumn('Inv Date', 'invoiceDate', true, 'desc', 'left'),
  new TableColumn('Payer', 'assetName', true, 'asc', 'left'),
  new TableColumn('Type', 'assetType', true, 'asc', 'centered'),
  new TableColumn('Reference', 'reference', true, 'asc', 'left'),
  new TableColumn('Contact', 'contactName', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const QuickItemColumns = [
  new TableColumn('ItemNo', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'description', true, 'asc', 'left'),
  new TableColumn('Quantity', 'quantity', false, 'asc', 'right'),
  new TableColumn('Unit Price', 'price', false, 'asc', 'right'),
  new TableColumn('', 'action', false, 'asc', 'centered')
]

export { Columns, QuickItemColumns }
